import { Button } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useMutation } from "react-query";

import { clearErrorMessage } from "@/hooks/utils";
import { LoginType } from "@/sample/queries";

import { SuccessNotification } from "../login/successNotification";
import { ErrorNotification } from "../login/errorNotification";
import { onLoginSuccess } from "./onLoginSuccess";

import axios, { AxiosError, AxiosResponse } from "axios";

type SSOError = {
  message: "Authentication Error";
  responseCode: "103";
  errors: {
    non_field_errors: [""];
  };
};

function ssoLogin(data: { session_key: string }) {
  const baseURL = process.env.NEXT_PUBLIC_BASE_URL;
  const login = new URL("login/sso", baseURL ?? "").href;

  return axios
    .post<LoginType, AxiosResponse<LoginType>, { session_key: string }>(
      login,
      data
    )
    .then(({ data }) => data)
    .catch((error: AxiosError<SSOError>) => {
      const { data } = { ...error.response };
      const { message = "Operation failed", errors } = { ...data };

      showNotification({
        title: message,
        message: clearErrorMessage(errors),
        color: "accent.8",
      });

      return undefined;
    });
}

export function SSO() {
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [URL, setURL] = useState("");

  const { query } = useRouter();
  const { q: session_key, ec } = query as Record<string, string>;

  const { mutate: sso, data: ssoData } = useMutation(ssoLogin, {
    onSuccess: onLoginSuccess({
      setShowSuccessNotification,
    }),
  });

  useEffect(() => {
    if (typeof window === "undefined") return;

    const SSO_URL = axios.getUri({
      baseURL: process.env.NEXT_PUBLIC_SSO_BASE_URL,
      params: {
        redirect_url: location.origin,
      },
    });

    setURL(SSO_URL);
  }, []);

  useEffect(() => {
    if (session_key) sso({ session_key });
  }, [session_key]);

  if (!process.env.NEXT_PUBLIC_SSO_BASE_URL) {
    throw new Error("AFEX SSO not set");
  }

  useEffect(() => {
    if (ec === "105") {
      showNotification({
        title: "Authentication Error",
        message:
          "Unrecognized service provider (SP) - this URL does not belong to any recognized SP",
        color: "red",
      });
    }

    if (ec === "106") {
      showNotification({
        title: "Authorization Error",
        message:
          "User does not have permission to the SP (Service Provider) - Contact support",
        color: "red",
      });
    }
  }, [ec]);

  return (
    <section className="flex flex-col gap-2">
      <a href={URL} target="_self">
        <Button
          fullWidth
          radius="md"
          className="font-semibold ecn-button"
          type="button"
          size="md"
        >
          Sign in with AFEX SSO
        </Button>
      </a>

      {showSuccessNotification && (
        <SuccessNotification
          close={setShowSuccessNotification}
          message={ssoData?.message}
        />
      )}

      {showErrorNotification && (
        <ErrorNotification
          close={setShowErrorNotification}
          message={ssoData?.message}
        />
      )}
    </section>
  );
}
