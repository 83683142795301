import ECNLogo from "@/public/company/ECN.svg";

import { Group, Stack } from "@mantine/core";
import Image from "next/future/image";
import Link from "next/link";

interface UnAuthLayoutProps {
  children: React.ReactNode;
  centerAlignLogo?: boolean;
}
export default function UnAuthLayout({
  children,
  centerAlignLogo,
}: UnAuthLayoutProps) {
  return (
    <div className="relative flex h-[100svh] w-screen sm:h-screen bg-[#E5E5E5] min-w-[200px]">
      <section
        className="absolute top-0 w-full max-w-[max(50%,720px)] min-w-[min(720px,100%)] h-full"
        style={{
          backgroundImage: "url(/company/background.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />
      <section className="w-[max(50%,720px)] z-10 min-w-[min(720px,100%)] flex flex-col justify-center items-center ml-auto">
        <div className="p-[clamp(12px,9vw,6rem)] max-w-xl bg-white w-11/12 shadow-csm rounded-[clamp(12px,5vw,2rem)]  text-[#434E5B]">
          <Stack spacing={50}>
            <Stack spacing="xl">
              <div
                className={centerAlignLogo ? "flex justify-center" : "block"}
              >
                <Link href="/" passHref>
                  <a>
                    <ECNLogo className="-ml-1" />
                  </a>
                </Link>
              </div>
              {children}
            </Stack>
            <Group position="center" spacing="xs">
              <span>POWERED BY</span>
              <figure className="relative w-[3.5em] h-[1em] text-[clamp(1.25rem,3vw,1.75rem)]">
                <Image src="/company/AFEX.png" alt="AFEX Logo" fill />
              </figure>
            </Group>
          </Stack>
        </div>
      </section>
    </div>
  );
}
